import React, { useEffect, useState } from "react";
import { callApi } from "../../common/utils/api";
import CustomInput from "../CustomInput";
import Error from "../../common/utils/Error";

const AwssSidebarContent = (props) => {
  const { toggleSidebar, addRowData, currentValue, getData } = props;

  const [formData, setFormData] = useState({
    name: {
      title: "Name",
      value: "",
      placeholder: "AWS - 001",
      type: "input",
    },
    aws_s: {
      title: "AWS Serial",
      value: "",
      placeholder: "Serial_12345",
      type: "input",
    },
    loc: {
      title: "Location",
      value: "",
      placeholder: "Pune",
      type: "select",
      options:[],
    },
  });
  useEffect(() => {
    console.log(currentValue, 'CURRENT_VALUE')
    setFormData({
      name: {
        title: "Name",
        value:  currentValue?.row?.n ? currentValue?.row?.n : "",
        placeholder: "AWS - 001",
        type: "input",
      },
      aws_s: {
        title: "AWS Serial",
        value:  currentValue?.row?.aws_s ? currentValue?.row?.aws_s : "",
        placeholder: "Serial_12345",
        type: "input",
      },
      loc: {
        title: "Location",
        value:  currentValue?.row?.loc?._id ? currentValue?.row?.loc?._id : "",
        placeholder: "Pune",
        type: "select",
        options:[],
      },
    });
    setTimeout(()=>{

      (async() => {
        let result_data = {};
        await callApi({
          endpoint: "api/locations",
        })
        .then((res) => {
          console.log(res);
          res.data.forEach((item) => {
            console.log(item._id, item.n)
            result_data[item._id] = item.n;
          });
          setFormData((prevFormData) => ({
            ...prevFormData,
            'loc': { ...prevFormData['loc'], options: result_data},
          }));
        })
        .catch((err) => {});})();
      }, 200)
      }, [currentValue]);
  const getLocations = async () => {
    if(currentValue){
      return;
    }
    let result_data = {};

    await callApi({
      endpoint: "api/locations",
    })
      .then((res) => {
        console.log(res);
        res.data.forEach((item) => {
          console.log(item._id, item.n)
          result_data[item._id] = item.n;
        });
        setFormData((prevFormData) => ({
          ...prevFormData,
          'loc': { ...prevFormData['loc'], options: result_data, value: Object.keys(result_data)[0] },
        }));
      })
      .catch((err) => {});
      
    return result_data;
  };
 

  React.useEffect(()=>{
    getLocations()
  }, [])

  const handleChange = (fieldName, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: { ...prevFormData[fieldName], value },
    }));
  };

  const handleSubmit = async (e) => {
    let keys = Object.keys(formData);
    for (let i = 0; i < keys.length; i++) {
      if (formData[keys[i]].value === "") {
        Error(`${formData[keys[i]].title} Cannot be empty `);
        return;
      }
    }
    let dataObject = {};
    keys.forEach((key) => {
      dataObject = { [key]: formData[key]?.value, ...dataObject };
    });

    await callApi({
      method: "POST",
      endpoint: "api/aws",
      data: dataObject,
      alert: true,
    })
      .then((res) => {
        setFormData((prevFormData) => {
          const updatedFormData = {};
          Object.keys(prevFormData).forEach((key) => {
            updatedFormData[key] = {
              ...prevFormData[key],
              value: "",
            };
          });
          return updatedFormData;
        });
        toggleSidebar(); // this toggle will close the right
        addRowData(res); // add the data to the data grid row
      })
      .catch((err) => {});
  };

  const handleUpdate = async (e) => {
    let keys = Object.keys(formData);
    let dataObject = {};
    keys.forEach((key) => {
      if (formData[key]?.value) {
        dataObject = { [key]: formData[key]?.value, ...dataObject };
      }
    });
    await callApi({
      method: "PATCH",
      endpoint: `api/aws/${currentValue?.id}`,
      data: dataObject,
      alert: true,
    })
      .then((res) => {
        setFormData((prevFormData) => {
          const updatedFormData = {};
          Object.keys(prevFormData).forEach((key) => {
            updatedFormData[key] = {
              ...prevFormData[key],
              value: "",
            };
          });
          return updatedFormData;
        });
        toggleSidebar(); // this toggle will close the right
        // addRowData(res); // add the data to the data grid row
        getData();
      })
      .catch((err) => {});
  };

  return (
    <div className="flex flex-col w-[100%] h-[90%] gap-4 p-4">
      {Object.keys(formData)?.map((element) => {
        return formData[element].type === "input" ? (
          <div className="flex flex-col gap-2">
            <label>{formData[element]?.title}</label>
            <CustomInput
              placeholder={formData[element]?.placeholder}
              value={formData[element]?.value}
              onChange={(e) => handleChange(element, e.target.value)}
            />
          </div>
        ) : formData[element].type === "select" ? (
          <div className="flex flex-col gap-2">
            <label>{formData[element]?.title}</label>
            {formData[element]?.options ? (
              <select
                className="transition block w-full pl-4 h-[40px] rounded-[4px] border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 hover:ring-indigo-600 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={(e) => {
                  
                console.log(e.target.value);
                handleChange(element, e.target.value)
                }}
              >
                {Object.keys(formData[element].options).length !== 0 ? (
                  Object.entries(formData[element].options).map(
                    ([key, value]) => {
                      return (
                        <option key={key} value={key}>
                          {value}
                        </option>
                      );
                    }
                  )
                ) : (
                  <option value={""}>no option</option>
                )}
              </select>
            ) : (
              <>
                Pass the key name options which is array of string in the object
                which mention types as select
              </>
            )}
          </div>
        ) : (
          <>TYPE NOT MATCHED</>
        );
      })}
      <button
        type="submit"
        onClick={currentValue ? handleUpdate : handleSubmit}
        className="transition mt-10 mb10 block w-full pl-4 h-[54px] rounded-[4px] font-semibold leading-6 text-white bg-indigo-500 border-0 py-1.5 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus: hover:bg-indigo-600  "
      >
       {currentValue ? "Update" : "Add"}
      </button>
    </div>
  );
};

export default AwssSidebarContent;
