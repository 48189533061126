import React from "react";

const HandIcon = ({ color = "black", size = 20 }) => {
  return (
    <svg
      height={size}
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        fill={color ? "color" : "currentColor"}
        d="M12.5 2a.5.5 0 00-.5.5V12h-2V4.5a.5.5 0 00-1 0V14H7c-.38-1.62-1.358-2.56-2.405-2.678.284.744.766 1.937 1.571 3.778.86 1.962 1.725 3.422 2.838 4.399C10.078 20.442 11.459 21 13.5 21a5.5 5.5 0 005.5-5.5V7a.5.5 0 00-1 0v5h-2V4a.5.5 0 00-1 0v8h-2V2.5a.5.5 0 00-.5-.5zM21 15.5a7.5 7.5 0 01-7.5 7.5c-2.458 0-4.328-.692-5.816-1.998-1.45-1.274-2.459-3.064-3.35-5.1-.93-2.127-1.444-3.422-1.724-4.178-.357-.964.136-2.312 1.476-2.406a4.02 4.02 0 012.914.94V4.5a2.5 2.5 0 013.04-2.442 2.5 2.5 0 014.79-.467A2.502 2.502 0 0118 4v.55A2.5 2.5 0 0121 7v8.5z"
      ></path>
    </svg>
  );
};

export default HandIcon;
