import React from "react";
import { useNavigate } from "react-router-dom";

const NotSupportedPage = () => {
  const navigate = useNavigate();

  return (
    <div className="grid place-items-center bg-black px-6 py-24 sm:py-32 lg:px-8 h-[100vh]">
      <div className="text-center">
        <p className="text-[60px] font-semibold text-indigo-600">404</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-400 sm:text-5xl">
          Page not supported
        </h1>
        <p className="mt-6 text-base leading-7 text-gray-400">
          We only support actual Screens at the moment. Please upgrade your plan for further access.
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <button
            onClick={() => {
              navigate(-1);
            }}
            className="text-sm font-semibold text-gray-400"
          >
            Go back
          </button>
          <button
            onClick={() => {
              navigate("/");
            }}
            className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Contact support <span aria-hidden="true">&rarr;</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotSupportedPage;
