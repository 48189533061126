import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { stateConnected } from "../common/store/redux_tools";
import OpenEye from "../common/core/icons/OpenEye";
import CloseEye from "../common/core/icons/CloseEye";
import LoginPageImg from "../common/core/Images/login_page.png";
import Error from "../common/utils/Error";
import { callApi } from "../common/utils/api";

const Login = (props) => {
  console.log("🚀 ~ file: Login.jsx:11 ~ Login ~ props:", props);
  const navigate = useNavigate();

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const [showPassword, setShowPassword] = useState(true);

  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    await callApi({
      method: "POST",
      endpoint: "api/users/login",
      alert: true,
      data: { username: userName, password: password },
    })
      .then((res) => {
        if (res?.token) {
          props._userLogin({ token: res.token });
          navigate("/")
        }
      })
      .catch((err) => {
        console.log("🚀 ~ file: Login.jsx:41 ~ handleSubmit ~ err:", err);
      });
  };

  return (
    <>
      <div className="flex w-[100vw] min-h-[100vh] justify-center">
        <div className="w-[40%] flex justify-center flex-col">
          <div className="sm:mx-auto sm:w-full sm:max-w-md  border-primary  pt-16 rounded-lg">
            <div className="flex flex-col gap-6 mb-16">
              <h2 className="text-left text-[46px] font-bold leading-9 tracking-tight text-gray-900 ">
                Log in
              </h2>
              <text>AWS Integrated IMD Screen Control Station</text>
            </div>
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-md">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-light leading-6 text-gray-900"
                >
                  Username
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    placeholder="coordinator"
                    required
                    className="transition block w-full pl-4 h-[54px] rounded-[4px] border-0 py-1.5 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 hover:ring-indigo-600   focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                  />
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between mt-6">
                  <label
                    htmlFor="password"
                    className="block text-sm font-light leading-6 text-gray-900"
                  >
                    Password
                  </label>
                  <div className="text-sm">
                    {/* <a
                    href="#"
                    className="font-semibold text-indigo-600 hover:text-indigo-500"
                  >
                    Forgot password?
                  </a> */}
                  </div>
                </div>
                <div className="mt-1 flex relative">
                  <input
                    id="password"
                    name="password"
                    type={showPassword ? "password" : "text"}
                    autoComplete="current-password"
                    placeholder="********"
                    required
                    className="transition block w-full pl-4 h-[54px] rounded-[4px] border-0 py-1.5 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 hover:ring-indigo-600   focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />

                  <button
                    className="absolute top-[2px] right-2 p-4"
                    onClick={toggleShowPassword}
                  >
                    {showPassword ? (
                      <CloseEye size={18} />
                    ) : (
                      <OpenEye size={18} />
                    )}
                  </button>
                </div>
              </div>
              <div className="text-xs my-4 text-gray-400 text-justify w-full justify-self-center">
                In case you forgot your password. Contact your Admin
              </div>
              <div>
                <button
                  type="submit"
                  onClick={handleSubmit}
                  className="transition mt-10 block w-full pl-4 h-[54px] rounded-[4px] font-semibold leading-6 text-white bg-indigo-500 border-0 py-1.5 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus: hover:bg-indigo-600  "
                >
                  Log in
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[60%] h-[100vh] bg-gray-100 flex justify-center items-center">
          <img
            className="object-cover w-full h-full"
            src={LoginPageImg}
            alt=""
          />
        </div>
      </div>
    </>
  );
};

export default stateConnected(Login);
