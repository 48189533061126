import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import Avatar from "react-avatar";
import { callApi } from "../common/utils/api";
import { stateConnected } from "../common/store/redux_tools";
import RightSidebar from "../components/sidebar/RightSidebar";
import ReusablePageComponent from "../components/ReusablePageComponent";
import LocationsSidebarContent from "../components/location/LocationsSidebarContent";
import EditIcon from "../common/core/icons/EditIcon";
import DeleteIcon from "../common/core/icons/DeleteIcon";
import CustomModal from "../components/CustomModal";

const Location = (props) => {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);

  const [currentValue, setCurrentvalue] = useState(null);

  const [parentRef, setParentRef] = useState({}); // ref for the data grid Mui

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const deleteModalRef = useRef(null);

  const onSetSidebarOpen = (open) => {
    setSidebarOpen(open);
  };
  const toggleChange = () => {
    setSidebarOpen((prev) => !prev);
    setCurrentvalue(null);
  };

  // Callback function to receive the child ref
  const handleChildRefChange = (childRef) => {
    // storing it in the parentRef or perform any other actions
    // parentRef = childRef;
    setParentRef(childRef);
  };

  const addRowData = (singleRow) => {
    setRows((prevRows) => [...prevRows, { ...singleRow, id: singleRow?._id }]);
  };
  const handleDelete = async (id) => {
    setLoading(true);
    await callApi({
      method: "DELETE",
      endpoint: `api/locations/${id}`,
    })
      .then((res) => {
        setRows((prev) => prev.filter((el) => el.id !== id));
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((err) => {
        setLoading(false);
        console.log("🚀 ~ file: Users.jsx:63 ~ handleDelete ~ err:", err);
      });
    deleteModalRef.current.closeModal();
  };
  const getData = () => {
    (async () => {
      await callApi({
        endpoint: "api/locations",
      })
        .then((res) => {
          let col = { ...res?.columns, actions: "Actions" };
          let rows = res?.data;
          let columnData = Object.keys(col)?.map((key) => {
            return {
              field: key,
              headerName: col[key],
              //   sortable: false,
              //   filterble: false,
              flex: 1,
              renderCell:
                key === "usn"
                  ? (params) => (
                      <div className="flex items-center">
                        <Avatar
                          name={params.row?.[key]}
                          size="35"
                          round={true}
                        />{" "}
                        &nbsp;&nbsp;{" "}
                        <span className="font-medium">{params.row?.[key]}</span>
                      </div>
                    )
                  : key === "role" && res?.roleMap
                  ? (params) => (
                      <div className="flex items-center">
                        {res?.roleMap[params.row?.[key]] === "admin" ? (
                          <>
                            <div className="bg-green-100 text-green-800 px-2 py-1 rounded-full">
                              Administrator
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="bg-sky-100 text-sky-800 px-2 py-1 rounded-full">
                              Incharge
                            </div>
                          </>
                        )}
                      </div>
                    )
                  : key === "awss"
                  ? (params) => (
                      <div className="flex items-center">
                        {params.row?.[key] && params.row?.[key].length > 0
                          ? params.row[key]
                              .map((aws_data) => aws_data.n)
                              .join(", ")
                          : ""}
                      </div>
                    )
                  : key === "ct" || key === "ut"
                  ? (params) => {
                      const timestamp = params.row?.[key];
                      const date = new Date(timestamp);

                      const formattedDate = moment(date)
                        .tz("Asia/Kolkata")
                        .format("MMM D, YYYY");

                      return formattedDate;
                    }
                  : key === "actions"
                  ? (params) => (
                      <div className="flex items-center gap-2">
                        <button
                          onClick={() => {
                            setSidebarOpen(true);
                            setCurrentvalue(params);
                          }}
                          className="p-1"
                        >
                          <EditIcon size={20} color="gray" />
                        </button>
                        <button
                          onClick={() => {
                            deleteModalRef.current.openModal();
                            setCurrentvalue(params);
                          }}
                          className="p-1"
                        >
                          <DeleteIcon color="gray" />
                        </button>
                      </div>
                    )
                  : (params) => params.row?.[col],
            };
          });
          setColumns(columnData);
          const rowsData = rows?.map((user) => {
            let singleObject = {};
            Object.keys(col).forEach((singleRow) => {
              singleObject = { ...singleObject, [singleRow]: user[singleRow] };
            });
            return {
              id: user._id,
              ...singleObject,
            };
          });
          setRows(rowsData);
          setLoading(false);
        })
        .catch((err) => {});
    })();
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <RightSidebar
        sidebarOpen={sidebarOpen}
        onSetSidebarOpen={onSetSidebarOpen}
        sidebarContent={
          <LocationsSidebarContent
            toggleSidebar={toggleChange}
            addRowData={addRowData}
            currentValue={currentValue}
            getData={getData}
          />
        }
      />

      <ReusablePageComponent
        heading={"Location"}
        subHeading={"Manage the Locations"}
        column={columns}
        rows={rows}
        loading={loading}
        setLoading={setLoading}
        toggleSidebar={toggleChange}
        onRefChange={handleChildRefChange} //lift refrence from child to parent
      />
      <CustomModal ref={deleteModalRef}>
        <div className="h-[100px] flex justify-center flex-col">
          <div className="text-lg mb-6"> Are you sure ?</div>
          <div className=" flex gap-2">
            <button
              className="p-2 border border-transprent rounded-lg bg-red-500 w-[25vh] text-white font-medium"
              onClick={() => handleDelete(currentValue?.id)}
            >
              Confirm
            </button>
            <button
              className="p-2 border border-gray-400 rounded-lg bg-gray-100 w-[25vh] font-medium"
              onClick={() => deleteModalRef.current.closeModal()}
            >
              Cancel
            </button>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default stateConnected(Location);
