import React, { useState } from "react";
import { stateConnected } from "../../common/store/redux_tools";
import CircleIcon from "../../common/core/icons/CircleIcon";
import RectangleIcon from "../../common/core/icons/RectangleIcon";
import TextIcon from "../../common/core/icons/TextIcon";
import DeleteIcon from "../../common/core/icons/DeleteIcon";
import TriangleIcon from "../../common/core/icons/TriangleIcon";
import FileDownloadIcon from "../../common/core/icons/FileDownloadIcon";
import RectangleToolProperties from "./tools-properties/RectangleToolProperties";
import CircleToolProperties from "./tools-properties/CircleToolProperties";
import LineToolProperties from "./tools-properties/LineToolProperties";
import TextToolProperties from "./tools-properties/TextToolProperties";
import TriangleToolProperties from "./tools-properties/TriangleToolProperties";
import ImageToolProperties from "./tools-properties/ImageToolProperties";
import AwsTools from "./tools-properties/AwsTools";
import MoveUpDown from "./tools-properties/MoveUpDown";
import Error from "../../common/utils/Error";
import { fabric } from "fabric";

const CreatorSidebar = (props) => {
  const {
    onAddCircle,
    onAddRectangle,
    onAddLine,
    onAddText,
    onAddTriangle,
    onDelete,
    onAddImage,
    tool,
    editor,
    canvasLoading,
    setCanvasLoading,
  } = props;

  const renderToolProperties = (selectedTool) => {
    switch (selectedTool) {
      case "circle": {
        return <CircleToolProperties {...props} />;
      }
      case "rect": {
        return <RectangleToolProperties {...props} />;
      }
      case "line": {
        return <LineToolProperties {...props} />;
      }
      case "text": {
        return <TextToolProperties {...props} />;
      }
      case "i-text": {
        return <TextToolProperties {...props} />;
      }
      case "triangle": {
        return <TriangleToolProperties {...props} />;
      }
      case "image": {
        return <ImageToolProperties {...props} />;
      }
      default:
        break;
    }
  };

  const [imageSource, setImageSource] = useState("");
  const [customText, setCustomText] = useState("");

  const downloadJsonFile = () => {
    const yourJsonData = props.instances;
    const jsonString = JSON.stringify(yourJsonData);
    const blob = new Blob([jsonString], { type: "application/json" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "yourJsonFile.json";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };
  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      setCanvasLoading(true);
      reader.onload = (e) => {
        try {
          setTimeout(() => {
            setCanvasLoading(false);
          }, 2000);
          let jsonData = JSON.parse(e.target.result);
          let finaldata = [];
          jsonData.forEach((element, index) => {
            finaldata.push(element);
          });
          if (finaldata[0]) {
            props._updateAllInstances([]);
            editor?.canvas?.clear();
            props._updateAllInstances(finaldata);
            props._updateCurrentInstance(props.instances.length + 1);
            setTimeout(() => {
              props._updateCurrentInstance(0);
            }, 100);
          } else {
            Error("Template Is Empty...");
            setCanvasLoading(false);
          }
          // const jsonData = JSON.parse(e.target.result);
          // editor?.canvas?.clear();
          // editor?.canvas?.loadFromJSON({
          //   objects: jsonData,
          // });
        } catch (error) {
          Error("File Format should be JSON");
          setCanvasLoading(false);
        }
      };

      reader.readAsText(file);
    }
  };

  const onAddImageSrc = (imgSrc) => {
    if (
      imgSrc.toString().includes(".jpg") ||
      imgSrc.toString().includes(".jpeg")
    ) {
      setCanvasLoading(true);
      const testImage = new Image();
      testImage.onload = () => {
        const canvasCenter = editor.canvas.getCenter();
        let random = Math.random().toString(36).slice(2);
        fabric.Image.fromURL(
          imgSrc,
          (img) => {
            // Set the position and size of the image
            img.set({
              left: canvasCenter.left,
              top: canvasCenter.top,
              originX: "center",
              originY: "center",
              id: "img-aws-" + random,
              scaleX: 0.5,
              scaleY: 0.5,
              crossOrigin: "anonymous", // Set crossOrigin here
            });

            // Add the image to the canvas
            editor.canvas.add(img);
            setImageSource("");
            setTimeout(() => {
              setCanvasLoading(false);
            }, 1000);
            // Render the canvas
            editor.canvas.renderAll();
          },
          { crossOrigin: "anonymous" } // Add crossOrigin to the options object
        );
      };
      testImage.onerror = () => {
        Error("Please Check your Src Url.");
        Error("Error in Loading Image.");
        setCanvasLoading(false);
      };
      testImage.src = imgSrc;
    } else {
      Error("Format Not supported");
      return;
    }
  };
  const onAddCustomText = () => {
    if (!customText) {
      Error("Text Cannot Be Empty.");
      return;
    }
    setCanvasLoading(true);
    const canvasCenter = editor.canvas.getCenter();
    let random = Math.random().toString(36).slice(2);
    const textOptions = {
      left: canvasCenter.left,
      top: canvasCenter.top,
      // fontFamily: "lato",
      id: "text-" + random,
      fontSize: 28,
      fill: "white",
      editable: false,
      originX: "center",
      originY: "center",
    };
    const text = new fabric.IText(customText, textOptions);

    // Add the text object to the canvas
    editor?.canvas.add(text);
    setCustomText("");
    setTimeout(() => {
      setCanvasLoading(false);
    }, 1000);
  };

  return (
    <div className="w-[425px] h-[100vh] bg-white shadow-lg p-4 gap-4 flex flex-col">
      <div className="flex flex-row flex-wrap w-[100%] gap-4 pb-4 h-[50%] border border-primary overflow-y-scroll w-full bg-white p-2">
        <div className="z-10 sticky border-none top-0 border bg-primary p-3 shadow-lg w-[100%]">
          <h2 className="text-white text-lg">Available Tools</h2>
        </div>
        {canvasLoading ? (
          <>
            <div className="p-3 w-[100%] animate-pulse">
              <h2 className="">Default Tools</h2>
            </div>
            {[0, 1, 2, 3, 4, 5].map((element) => {
              return (
                <div class="border border-white-300 bg-white w-[100%] ml-4 mr-4 shadow p-4 max-w-sm animate-pulse bg-white-500 cursor-wait">
                  <div class="animate-pulse flex space-x-4">
                    <div class="rounded-full bg-slate-200 h-10 w-10"></div>
                    <div class="flex-1 space-y-6 py-1">
                      <div class="space-y-3">
                        <div class="grid grid-cols-3 gap-4">
                          <div class="h-2 bg-slate-200 rounded col-span-2"></div>
                          <div class="h-2 bg-slate-200 rounded col-span-1"></div>
                        </div>
                        <div class="h-2 bg-slate-200 rounded"></div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <>
            <div className="p-3 w-[100%]">
              <h2 className="">Default Tools</h2>
            </div>
            <button
              className="border border-primary text-primary flex gap-2 bg-white items-center p-2 shadow-sm h-[50px] ml-2 w-[110px] rounded"
              onClick={onAddCircle}
            >
              <CircleIcon color={"#7367f0"} />
              <p className="text-mono">Circle </p>
            </button>
            <button
              className="border border-primary text-primary flex gap-2 bg-white items-center p-2 shadow-sm h-[50px] ml-2 w-[130px] rounded"
              onClick={onAddRectangle}
            >
              <RectangleIcon color={"#7367f0"} />
              <p className="text-mono">Rectangle </p>
            </button>
            <button
              className="border border-primary text-primary flex gap-2 bg-white items-center p-2 shadow-sm h-[50px] ml-2 w-[80px] rounded"
              onClick={onAddLine}
            >
              <div className="">-</div>
              <p className="text-mono">Line </p>
            </button>
            <button
              className="border border-primary text-primary flex gap-2 bg-white items-center p-2 shadow-sm h-[50px] ml-2 rounded"
              onClick={onAddText}
            >
              <TextIcon color={"#7367f0"} />
              <p className="text-mono">Text </p>
            </button>
            <button
              className="border border-primary text-primary flex gap-2 bg-white items-center p-2 shadow-sm h-[50px] ml-2 rounded"
              onClick={onAddTriangle}
            >
              <TriangleIcon color={"#7367f0"} />
              <p className="text-mono">Triangle </p>
            </button>
            <div class="flex items-center justify-center w-[95%] ml-2 rounded">
              <label
                for="file-selector"
                className="flex flex-col items-center justify-center w-full border border-primary border-dashed cursor-pointer"
              >
                <div className="flex flex-col items-center justify-center pt-2 pb-2 rounded">
                  <svg
                    class="w-8 h-8 mb-1 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                  >
                    <path
                      stroke="#7367f0"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                    />
                  </svg>
                  <p class="mb-2 text-sm text-primary">
                    <span class="font-semibold">Click to upload</span> jpg ,
                    jpeg , png
                  </p>
                  {/* <p class="text-xs text-primary">Json</p> */}
                </div>
                <input
                  name={"Upload Image"}
                  type="file"
                  id="file-selector"
                  accept=".jpg, .jpeg, .png"
                  // onClick={onAddImage}
                  onChange={onAddImage}
                  class="hidden"
                />
              </label>
            </div>
            {/* <div className="border border-primary text-primary flex gap-2 bg-white items-center p-2 shadow-sm h-[50px] ml-2 rounded">
              <input
                name={"Upload Image"}
                type="file"
                id="file-selector"
                accept=".jpg, .jpeg, .png"
                // onClick={onAddImage}
                onChange={onAddImage}
              />
            </div> */}
            <div className="border border-primary text-primary flex bg-white items-center ml-2 w-[95%] shadow-sm h-[50px] rounded">
              <input
                className="appearance-none placeholder-purple-200 text-primary bg-transparent border-none w-full mr-3 py-1 px-2 leading-tight focus:outline-none"
                type="url"
                placeholder="https://example.jpg"
                // aria-label="src"
                onChange={(e) => {
                  setImageSource(e.target.value);
                }}
              />
              <button
                class="flex-shrink-0 bg-transparent hover:bg-primary border-1 border-transparent border-l border-l-primary hover:text-white py-2 text-sm text-primary py-1 px-2 h-full"
                type="button"
                onClick={() => {
                  onAddImageSrc(imageSource);
                }}
              >
                Add Url
              </button>
            </div>
            {/* <div className="border border-primary text-primary flex gap-4 bg-white items-center justify-between p-4 shadow-sm h-[50px] ml-2 mr-4 rounded">
              <input
                value={imageSource}
                type="text"
                accept=".jpg, .jpeg, .png"
                onChange={(e) => {
                  setImageSource(e.target.value);
                }}
                className="border border-primary"
              />
              <button
                className="border border-primary p-1"
                onClick={() => {
                  onAddImageSrc(imageSource);
                }}
              >
                <text className="text-[16px]">Add Image</text>
              </button>
            </div> */}
            <div className="border border-primary text-primary flex bg-white items-center ml-2 w-[95%] shadow-sm h-[50px] rounded">
              <input
                className="appearance-none placeholder-purple-200 text-primary bg-transparent border-none w-full mr-3 py-1 px-2 leading-tight focus:outline-none"
                value={customText}
                type="text"
                placeholder="Paste any language & add."
                onChange={(e) => {
                  setCustomText(e.target.value);
                }}
              />
              <button
                class="flex-shrink-0 bg-transparent hover:bg-primary border border-transparent border-l border-l-primary hover:border-primary hover:text-white py-2 text-sm border text-primary py-1 px-2 h-full"
                type="button"
                onClick={() => {
                  onAddCustomText();
                }}
              >
                Add Font
              </button>
            </div>
            {/* <div className="border border-primary text-primary flex gap-4 bg-white items-center justify-between p-4 shadow-sm h-[50px] ml-2 mr-4 rounded">
              <input
                value={customText}
                type="text"
                onChange={(e) => {
                  setCustomText(e.target.value);
                }}
                className="border border-primary"
              />
              <button
                className="border border-primary p-1"
                onClick={() => {
                  onAddCustomText();
                }}
              >
                <text className="text-[16px]">Add Font</text>
              </button>
            </div> */}
            {tool && (
              <button
                className="border border-primary text-primary flex gap-4 bg-white items-center p-4 shadow-sm h-[50px] ml-2 mr-2 rounded"
                onClick={onDelete}
              >
                <DeleteIcon color={"#7367f0"} />
                <p className="text-mono">Delete </p>
              </button>
            )}
            <button
              className="border border-primary text-primary flex gap-4 bg-white items-center p-4 shadow-sm h-[50px] ml-2 mr-2 rounded"
              onClick={downloadJsonFile}
            >
              <FileDownloadIcon color={"#7367f0"} />
              <p className="text-mono">Download File </p>
            </button>
            {/* <div className="border border-primary text-primary flex gap-4 bg-white items-center p-4 shadow-sm h-[50px] ml-2 mr-4">
          <p>Load Template</p>
          <input
            name={"Load TEMP"}
            type="file"
            accept=".json"
            onChange={handleFileUpload}
          />
        </div> */}
            <div class="flex items-center justify-center w-[95%] ml-2 rounded">
              <label
                for="dropzone-file"
                class="flex flex-col items-center justify-center w-full border border-primary border-dashed cursor-pointer"
              >
                <div class="flex flex-col items-center justify-center pt-2 pb-2 rounded">
                  <svg
                    class="w-8 h-8 mb-2 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                  >
                    <path
                      stroke="#7367f0"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                    />
                  </svg>
                  <p class="mb-2 text-sm text-primary">
                    <span class="font-semibold">Click to upload</span> json
                    file.
                  </p>
                  {/* <p class="text-xs text-primary">Json</p> */}
                </div>
                <input
                  id="dropzone-file"
                  type="file"
                  class="hidden"
                  accept=".json"
                  onChange={handleFileUpload}
                />
              </label>
            </div>
          </>
        )}

        <br />
        <AwsTools {...props} />
      </div>
      {tool && (
        <div className="flex flex-col pb-2 gap-2 h-[50%] overflow-y-scroll w-full border border-primary">
          <div className="z-10 sticky border-none top-0 border bg-primary p-3 shadow-lg">
            <h2 className="text-white text-lg">{`Selected Tool ${tool}`}</h2>
          </div>
          <div className="bg-white shadow-sm m-2 ml-2 mr-4">
            <MoveUpDown {...props} />
            {renderToolProperties(tool)}
          </div>
        </div>
      )}
    </div>
  );
};

export default stateConnected(CreatorSidebar);
