import React, { useEffect, useState } from "react";
import { callApi } from "../common/utils/api";
import { stateConnected } from "../common/store/redux_tools";
import ReusablePageComponent from "../components/ReusablePageComponent";

const HomePage = (props) => {
  console.log("🚀 ~ file: HomePage.jsx:7 ~ HomePage ~ props:", props);
  const [rows, setRows] = useState([
    { id: 1, col1: "Hello", col2: "World", col3: "jhdjh" },
    { id: 2, col1: "Hello", col2: "World", col3: "jhdjh" },
    { id: 3, col1: "Hello", col2: "World", col3: "jhdjh" },
    { id: 4, col1: "Hello", col2: "World", col3: "jhdjh" },
    { id: 5, col1: "Hello", col2: "World", col3: "jhdjh" },
    { id: 6, col1: "Hello", col2: "World", col3: "jhdjh" },
    { id: 7, col1: "Hello", col2: "World", col3: "jhdjh" },
    { id: 8, col1: "Hello", col2: "World", col3: "jhdjh" },
    { id: 9, col1: "hhih", col2: "Woccrld", col3: "jhdjh" },
    { id: 10, col1: "Hello", col2: "World", col3: "jhdjh" },
    { id: 11, col1: "Hello", col2: "World", col3: "jhdjh" },
    { id: 12, col1: "Hello", col2: "World", col3: "jhdjh" },
    { id: 13, col1: "Hello", col2: "World", col3: "jhdjh" },
    { id: 14, col1: "Hello", col2: "World", col3: "jhdjh" },
    { id: 15, col1: "kjbj", col2: "Worcasxld", col3: "jhdjh" },
  ]);

  const [columns, setColumns] = useState([
    {
      field: "col1",
      headerName: "Name",
      // sortable: false,
      filterble: false,
      flex: 1,
      renderCell: (params) => (
        <div className="flex items-center">
          <div className="border border-black-500 p-2 mr-2 bg-lightgray rounded-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="14"
              height="14"
              fill="black"
            >
              <path d="M12.0009 17C15.6633 17 18.8659 18.5751 20.608 20.9247L18.766 21.796C17.3482 20.1157 14.8483 19 12.0009 19C9.15346 19 6.6535 20.1157 5.23577 21.796L3.39453 20.9238C5.13673 18.5747 8.33894 17 12.0009 17ZM12.0009 2C14.7623 2 17.0009 4.23858 17.0009 7V10C17.0009 12.6888 14.8786 14.8818 12.2178 14.9954L12.0009 15C9.23945 15 7.00087 12.7614 7.00087 10V7C7.00087 4.31125 9.12318 2.11818 11.784 2.00462L12.0009 2ZM12.0009 4C10.4032 4 9.09721 5.24892 9.00596 6.82373L9.00087 7V10C9.00087 11.6569 10.344 13 12.0009 13C13.5986 13 14.9045 11.7511 14.9958 10.1763L15.0009 10V7C15.0009 5.34315 13.6577 4 12.0009 4Z"></path>
            </svg>
          </div>
          {params.row?.col1}
        </div>
      ),
    },
    {
      field: "col2",
      headerName: "Column 2",
      sortable: false,
      filter: false,
      flex: 1,
    },
    {
      field: "col3",
      headerName: "Column 3",
      sortable: false,
      filter: false,
      flex: 1,
    },
    {
      field: "col4",
      headerName: "Progress",
      sortable: false,
      filter: false,
      flex: 0.5,
    },
    {
      field: "col5",
      headerName: "Status",
      sortable: false,
      filter: false,
      flex: 0.5,
    },
    {
      field: "col6",
      headerName: "Action",
      sortable: false,
      filter: false,
      flex: 0.5,
    },
  ]);

  useEffect(() => {
    (async () => {
      await callApi({
        endpoint: "api/users",
      })
        .then((res) => {})
        .catch((err) => {});
    })();
  }, []);

  return (
    <div>
      <ReusablePageComponent
        heading={"Homes"}
        subHeading={"Location subheading"}
        column={columns}
        rows={rows}
        sidebarComponent={<>Sidebar</>}
        renderCell={<>Custom Cell Render</>}
      />
    </div>
  );
};

export default stateConnected(HomePage);
