import React, { useEffect, useState } from "react";
import { stateConnected } from "../common/store/redux_tools";
import { callApi } from "../common/utils/api";
import { useNavigate } from "react-router-dom";

const CommandCenter = (props) => {
  const [images, setImages] = useState([
    "https://images.unsplash.com/photo-1514707363198-72452c95dd48?w=420&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXRodW1ibmFpbHx8ODQ5ODQ1Mnx8ZW58MHx8fHx8",
    "https://pixlr.com/images/index/ai-image-generator-two.webp",
    "https://th.bing.com/th/id/OIG.b7fbyFnoRp_h0eDim7rl",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCeu_2xfqounYauO2lOTc97tuTWTGey832_g&usqp=CAU",
    "https://2.img-dpreview.com/files/p/E~C1000x0S4000x4000T1200x1200~articles/3925134721/0266554465.jpeg",
    "https://images.unsplash.com/photo-1514707363198-72452c95dd48?w=420&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXRodW1ibmFpbHx8ODQ5ODQ1Mnx8ZW58MHx8fHx8",
    "https://pixlr.com/images/index/ai-image-generator-two.webp",
    "https://th.bing.com/th/id/OIG.b7fbyFnoRp_h0eDim7rl",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCeu_2xfqounYauO2lOTc97tuTWTGey832_g&usqp=CAU",
    "https://2.img-dpreview.com/files/p/E~C1000x0S4000x4000T1200x1200~articles/3925134721/0266554465.jpeg",
    "https://th.bing.com/th/id/OIG.b7fbyFnoRp_h0eDim7rl",
  ]);

  const navigate = useNavigate();

  useEffect(() => {
    // (async () => {
    //   await callApi({
    //     endpoint: `api/screen`,
    //     alert: true,
    //   })
    //     .then((res) => {
    //       console.log("🚀 ~ file: CommandCenter.jsx:13 ~ .then ~ res:", res);
    //     })
    //     .catch((err) => {});
    // })();
  }, []);
  return (
    <div className="h-[92%] border-2 overflow-y-scroll">
      <div className="row-span-2 grid h-3/5 grid-cols-5 bg-white">
        {images?.map((element, index) => {
          return (
            <div
              className={`w-full h-full border hover:cursor-pointer border pl-4 pt-4 ${
                index % 2 === 0 ? "row-span-1" : "row-span-2"
              }`}
              onClick={() => {
                navigate(`/`);
              }}
            >
              <img
                className="object-cover w-full h-full"
                src={element}
                alt=""
                srcset=""
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default stateConnected(CommandCenter);
