import React, { useState } from "react";
import CustomInput from "../CustomInput";
import Error from "../../common/utils/Error";
import { callApi } from "../../common/utils/api";

const UsersSidebarContent = (props) => {
  const { toggleSidebar, addRowData } = props;
  const [formData, setFormData] = useState({
    first_name: {
      title: "First Name",
      value: "",
      placeholder: "John",
      type: "input",
    },
    last_name: {
      title: "Last name",
      value: "",
      placeholder: "Williams",
      type: "input",
    },
    username: {
      title: "User Name",
      value: "",
      placeholder: "johnwilliams",
      type: "input",
    },
    role: {
      title: "Role",
      value: "incharge",
      placeholder: "johnwilliams",
      type: "select",
      options: ["admin", "incharge"],
    },
    password: {
      title: "password",
      value: "",
      placeholder: "*****",
      type: "password",
    },
    confirm_password: {
      title: "confirm password",
      value: "",
      placeholder: "*****",
      type: "password",
    },
  });

  const handleChange = (fieldName, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: { ...prevFormData[fieldName], value },
    }));
  };

  const handleSubmit = async (e) => {
    let keys = Object.keys(formData);
    for (let i = 0; i < keys.length; i++) {
      if (formData[keys[i]].value === "") {
        Error(`${formData[keys[i]].title} Cannot be empty `);
        return;
      }
    }
    let dataObject = {};
    keys.forEach((key) => {
      dataObject = { [key]: formData[key]?.value, ...dataObject };
    });

    await callApi({
      method: "POST",
      endpoint: "api/users",
      data: dataObject,
      alert: true,
    })
      .then((res) => {
        setFormData((prevFormData) => {
          const updatedFormData = {};
          Object.keys(prevFormData).forEach((key) => {
            updatedFormData[key] = {
              ...prevFormData[key],
              value: "",
            };
          });
          return updatedFormData;
        });
        toggleSidebar(); // this toggle will close the right
        addRowData(res); // add the data to the data grid row
      })
      .catch((err) => {});
  };

  return (
    <div className="flex flex-col w-[100%] h-[90%] gap-4 p-4">
      {Object.keys(formData)?.map((element) => {
        return formData[element].type === "input" ? (
          <div className="flex flex-col gap-2">
            <label>{formData[element]?.title}</label>
            <CustomInput
              placeholder={formData[element]?.placeholder}
              value={formData[element]?.value}
              onChange={(e) => handleChange(element, e.target.value)}
            />
          </div>
        ) : formData[element].type === "password" ? (
          <div className="flex flex-col gap-2">
            <label>{formData[element]?.title}</label>
            <CustomInput
              placeholder={formData[element]?.placeholder}
              value={formData[element]?.value}
              onChange={(e) => handleChange(element, e.target.value)}
              type="password"
            />
          </div>
        ) : formData[element].type === "select" ? (
          <div className="flex flex-col gap-2">
            <label>{formData[element]?.title}</label>
            {formData[element]?.options ? (
              <select
                className="transition block w-full pl-4 h-[40px] rounded-[4px] border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 hover:ring-indigo-600 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={(e) => handleChange(element, e.target.value)}
              >
                {formData[element]?.options.length !== 0 ? (
                  formData[element]?.options?.map((option) => {
                    return <option value={option}>{option}</option>;
                  })
                ) : (
                  <option value={""}>no option</option>
                )}
              </select>
            ) : (
              <>
                Pass the key name options which is array of string in the object
                which mention types as select
              </>
            )}
          </div>
        ) : (
          <>TYPE NOT MATCHED</>
        );
      })}
      <button
        type="submit"
        onClick={handleSubmit}
        className="transition mt-10 mb10 block w-full pl-4 h-[54px] rounded-[4px] font-semibold leading-6 text-white bg-indigo-500 border-0 py-1.5 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus: hover:bg-indigo-600  "
      >
        Add
      </button>
    </div>
  );
};

export default UsersSidebarContent;
