import { useState } from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import PersonIcon from "../common/core/icons/PersonIcon";
import SettingsIcon from "../common/core/icons/SettingsIcon";
import LogoutIcon from "../common/core/icons/LogoutIcon";
import { useNavigate } from "react-router-dom";
import Avatar from "react-avatar";

function AvatarWithOptions(props) {
  const { isProfileOpen, setIsProfileOpen } = props;
  const navigate = useNavigate();

  const [profileOptions, setOptions] = useState([
    {
      id: "1",
      title: "Profile",
      icon: <PersonIcon />,
      clickHandler: () => {
        // navigate("/");
      },
    },
    {
      id: "2",
      title: "Settings",
      icon: <SettingsIcon />,
      clickHandler: () => {},
    },
    {
      id: "3",
      title: "Logout",
      icon: <LogoutIcon />,
      clickHandler: () => {
        props._userLogout({ token: null });
        props._updateAllInstances([]);
      },
    },
  ]);

  return (
    <DropdownMenu.Root
      onOpenChange={(e) => {
        setIsProfileOpen(e);
      }}
      open={isProfileOpen}
    >
      <DropdownMenu.Trigger asChild>
        <button
          className="rounded-full border border-gray-300 mr-4 mt-1"
          aria-label="Customise options"
        >
          {/* <svg
            width={18}
            height={18}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path
              fill="lightgray"
              d="M20 22H18V20C18 18.3431 16.6569 17 15 17H9C7.34315 17 6 18.3431 6 20V22H4V20C4 17.2386 6.23858 15 9 15H15C17.7614 15 20 17.2386 20 20V22ZM12 13C8.68629 13 6 10.3137 6 7C6 3.68629 8.68629 1 12 1C15.3137 1 18 3.68629 18 7C18 10.3137 15.3137 13 12 13ZM12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"
            ></path>
          </svg> */}
          <Avatar name={props?.auth?.name || "ADMIN"} size="46" round={true} />
        </button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className="bg-white shadow-xl pl-2 pr-2 mr-2 p-3 w-[300px] border flex flex-col gap-1 border "
          sideOffset={5}
          style={{ zIndex: 2 }}
        >
          {profileOptions?.map((element, index) => {
            return (
              <>
                <DropdownMenu.Item
                  key={element.id + index}
                  onClick={element.clickHandler}
                  className="p-2 py-3 hover:bg-gray-200 hover:cursor-pointer text-sm text-gray-400 hover:text-gray-900 border-r-transparent border-r-[6px] hover:border-r-primary hover:bg-indigo-100 ease-in duration-500 "
                >
                  <text className="flex gap-4 items-center text-lg">
                    <span>{element.icon}</span>
                    {element.title}
                  </text>
                </DropdownMenu.Item>
                {index === 1 && (
                  <DropdownMenu.Separator className="DropdownMenuSeparator border" />
                )}
              </>
            );
          })}
          <DropdownMenu.Arrow className="fill-gray-500" />
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}

export default AvatarWithOptions;
