import CommandIcon from "../core/icons/CommandIcon";
import DisplayIcon from "../core/icons/DisplayIcon";
import HomeIcon from "../core/icons/HomeIcon";
import LocationIcon from "../core/icons/LocationIcon";
import PeopleIcon from "../core/icons/PeopleIcon";
import UsersIcon from "../core/icons/UsersIcon";

export const LOG_IN = "LOG_IN";
export const LOG_OUT = "LOG_OUT";

export const SUPPORTED_OPERATING_SYSTEM = "Chromium OS";

export const NETWORK_ERROR = "NETWORK_ERROR";
export const SERVER_ERROR = "SERVER_ERROR";

// Shape Types
export const RECTANGLE = "RECTANGLE";
export const CIRCLE = "CIRCLE";
export const LINE = "LINE";
export const TEXT = "TEXT";
export const IMAGE = "IMAGE";

// Action Types
export const ADD_RECTANGLE = "ADD_RECTANGLE";
export const UPDATE_RECTANGLE = "UPDATE_RECTANGLE";
export const DELETE_RECTANGLE = "DELETE_RECTANGLE";

export const ADD_CIRCLE = "ADD_CIRCLE";
export const UPDATE_CIRCLE = "UPDATE_CIRCLE";
export const DELETE_CIRCLE = "DELETE_CIRCLE";

export const ADD_LINE = "ADD_LINE";
export const UPDATE_LINE = "UPDATE_LINE";
export const DELETE_LINE = "DELETE_LINE";

export const ADD_TEXT = "ADD_TEXT";
export const UPDATE_TEXT = "UPDATE_TEXT";
export const DELETE_TEXT = "DELETE_TEXT";

export const ADD_IMAGE = "ADD_IMAGE";
export const UPDATE_IMAGE = "UPDATE_IMAGE";
export const DELETE_IMAGE = "DELETE_IMAGE";

export const ADD_INSTANCE = "ADD_INSTANCE";
export const UPDATE_INSTANCE = "UPDATE_INSTANCE";
export const DELETE_INSTANCE = "DELETE_INSTANCE";

export const UPDATE_CURRENT_INSTANCE = "UPDATE_CURRENT_INSTANCE";
export const UPDATE_ALL_INSTANCES = "UPDATE_ALL_INSTANCES";

export const sidebarMenus = [
  // {
  //   title: "Home",
  //   src: "/",
  //   icon: <HomeIcon size={22} />,
  // },
  {
    title: "Location",
    src: "/",
    icon: <LocationIcon size={22} />,
  },
  {
    title: "Aws",
    src: "/aws",
    icon: <HomeIcon size={22} />,
  },
  {
    title: "Screen",
    src: "/display",
    icon: <DisplayIcon size={22} />,
  },
  {
    title: "Users",
    src: "/users",
    icon: <PeopleIcon size={22} />,
  },
  // {
  //   title: "Command Center",
  //   src: "/command-center",
  //   icon: <CommandIcon size={22} />,
  // },
];
