import React, { useState } from "react";
import SearchInput from "./SearchInput";
import AvatarWithOptions from "./AvatarWithOptions";
import { stateConnected } from "../common/store/redux_tools";

const Header = (props) => {
  const handleChange = (searchValue) => {
    setSearch(searchValue);
  };
  const [search, setSearch] = useState("");
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  return (
    <div className="z-[1] fl<ex justify-between align-center border-bottom-1 h-[60px] sticky top-0 border bg-white">
      <div className="pl-6 flex justify-center align-center">
        {/* <SearchInput onChange={handleChange} value={search} /> */}
      </div>
      <div className="flex flex items-center justify-end gap-2 self-center">
        <AvatarWithOptions
          isProfileOpen={isProfileOpen}
          setIsProfileOpen={setIsProfileOpen}
          {...props}
        />
      </div>
    </div>
  );
};

export default stateConnected(Header);
