/* LIBRARIES */
import axios from "axios";
import { has, isEmpty } from "lodash";

/* ABSOLUTE IMPORTS */
import { getBaseURL } from "../constant/urls";
import Alert from "./Alert";
import Error from "./Error";
import { NETWORK_ERROR, SERVER_ERROR } from "../constant/strings";

/**
 * updated version of call Api function
 * @param {String} method   default GET
 * @param {String} url      default null
 * @param {String} endpoint endpoint along with the query params default ""
 * @param {enum}   type     data type which is being sent JSON/FORMDATA
 * @param {Object} data     Object data needs to be send in POST request default {}
 * @returns
 */
export const callApi = ({
  method = "GET",
  url = null,
  endpoint = "",
  type = "json",
  headers = {},
  data = {},
  additionalProps = {},
  alert = false,
} = {}) => {
  // retruning a promise to resolve
  return new Promise((resolve, reject) => {
    //get auth token
    // let token = store.getState()?.auth?.token;
    let token = "";
    // header based on different endpoint calls
    let headerTypes = {
      json: { "content-type": `application/json` },
      formdata: { "content-type": `multipart/form-data` },
    };

    try {
      //creation of default data for the endpoint call
      let defaultData = {
        method: method.toUpperCase(),
        url: url ? url : getBaseURL() + "/" + endpoint,
        headers: {
          ...headerTypes[type],
          ...headers,

          Authorization: has(headers, "Authorization")
            ? headers.Authorization
            : "Bearer " + token,
        },
        data: data,
        ...additionalProps,
      };

      //axios call
      axios(defaultData)
        .then((res) => {
          if (alert) {
            Alert(res.data.message);
          }
          resolve(res.data.data);
        })
        .catch((err) => {
          console.log(err, 'ERRR')
          //if user cancels the request in between
          if (err?.response?.status === 404) {
            Error("Connect to internet, or login again");
            reject(err);
          }
          if (has(additionalProps, "cancelToken")) {
            if (axios.isCancel(err)) {
              if (alert) {
                Error(err.message);
              }
              reject(err);
            } else if (err.response) {
              if (err?.response?.status === 500) {
                if (alert) {
                  Error(err.toString());
                }
                reject(err);
              } else {
                if (err.response.data.message) {
                  if (alert) {
                    Error(err.response.data.message);
                  }
                }
                resolve(err);
              }
            }
          }
          //server error
          let SERVER_ERROR_BOOL =
            err.response?.data?.status === 500 || isEmpty(err.response);
          if (SERVER_ERROR_BOOL) {
            if (alert) {
              if (err.code === "ECONNABORTED") {
                Error(err.message);
              } else {
                Error(
                  SERVER_ERROR +
                    (!isEmpty(err.response)
                      ? ": " + err.response?.data?.message
                      : "")
                );
              }
            }
          }

          // other than server errors
          else {
            if (alert) {
              Error(err.response.data.message);
            }
          }

          reject(err.response?.data?.error);
        });
    } catch (e) {
      Error(NETWORK_ERROR);
      reject(e.toString());
    }
  });
};
