import React, { useEffect, useRef, useState } from "react";
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import { stateConnected } from "../common/store/redux_tools";
import { useParams } from "react-router-dom";
import { callApi } from "../common/utils/api";
import { osName } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { SUPPORTED_OPERATING_SYSTEM } from "../common/constant/strings";
import { isEmpty } from "lodash";

const Preview = (props) => {
  const { selectedObjects, editor, onReady } = useFabricJSEditor();
  const { id } = useParams();
  const navigate = useNavigate();

  const countRef = React.useRef(0);
  const [toggle, setToggle] = useState(false);
  const [aws_id, setAws_Id] = useState("");
  const [aws_d, setAws_d] = useState("");
  const [currTime, setCurrTime] = useState("");

  const awsIntervalRef = useRef(null);
  const updateDataIntervalRef = useRef(null);

  const [timeMode, setTimeMode] = useState(getTimeUpdates());

  function getTimeUpdates() {
    const currentDate = new Date();
    const currentHour = currentDate.getHours();
    const currentMinutes = currentDate.getMinutes();

    if (
      (currentHour === 18 && currentMinutes >= 30) ||
      currentHour > 18 ||
      currentHour < 6
    ) {
      return "NIGHT";
    } else {
      return "MORNING";
    }
  }

  const getAllData = async (e) => {
    await callApi({
      endpoint: `api/screen/${id}`,
    })
      .then((res) => {
        setAws_Id(res?.aws);
        console.log(res.aws);
        // setTimeout(() => {
        //   window.location.reload();
        // }, 10000 * res?.templ?.length || 1);
        props?._updateAllInstances([]);
        setTimeout(() => {
          props._updateAllInstances(res.templ);
          if (props.instances[props.currentInstance]?.data) {
            editor?.canvas?.loadFromJSON({
              objects: props?.instances?.[props.currentInstance].data,
            });
            editor?.canvas?.renderAll();
            editor?.canvas?.setDimensions({
              width: props?.instances?.[props.currentInstance].width,
              height: props?.instances?.[props.currentInstance].height,
            });
            setTimeout(() => {
              editor?.canvas?.renderAll();
            }, 500);
          }
          // const oneHourInMilliseconds = 60 * 60 * 1000;
          const oneHourInMilliseconds = 60 * 180 * 1000;
          setTimeout(() => {
            window.location.reload();
          }, oneHourInMilliseconds);
          awsIntervalRef.current = setInterval(() => {
            (async () => {
              await callApi({
                endpoint: `api/aws/${res.aws}`,
              })
                .then((res) => {
                  setAws_d(res.aws_d);
                  if (props.instances[props.currentInstance]?.data) {
                    editor?.canvas?.loadFromJSON({
                      objects: props?.instances?.[props.currentInstance].data,
                    });
                    editor?.canvas?.renderAll();
                    editor?.canvas?.setDimensions({
                      width: props?.instances?.[props.currentInstance].width,
                      height: props?.instances?.[props.currentInstance].height,
                    });
                    setTimeout(() => {
                      editor?.canvas?.renderAll();
                    }, 500);
                  }
                })
                .catch((err) => {});
            })();
          }, 15000);
          updateDataIntervalRef.current = setInterval(() => {
            (async () => {
              await getUpdatedData().then((res) => {});
            })();
          }, 15000);
        }, 1000);
      })
      .catch((err) => {
        console.log('SETTING AWS UPDATEdddd')
        updateDataIntervalRef.current = setInterval(() => {
          (async () => {
            await getUpdatedData().then((res) => {});
          })();
        }, 15000);
      });
  };
  const getUpdatedData = async (e) => {
    console.log("SETTING AWS UPDATE")
    await callApi({
      endpoint: `api/screen/${id}`,
    })
      .then((res) => {
        setTimeout(() => {
          props._updateAllInstances(res.templ);
          setCurrTime(res.time);
          if(!awsIntervalRef.current){
            awsIntervalRef.current = setInterval(() => {
              (async () => {
                await callApi({
                  endpoint: `api/aws/${res.aws}`,
                })
                  .then((res) => {
                    setAws_d(res.aws_d);
                    if (props.instances[props.currentInstance]?.data) {
                      editor?.canvas?.loadFromJSON({
                        objects: props?.instances?.[props.currentInstance].data,
                      });
                      editor?.canvas?.renderAll();
                      editor?.canvas?.setDimensions({
                        width: props?.instances?.[props.currentInstance].width,
                        height: props?.instances?.[props.currentInstance].height,
                      });
                      setTimeout(() => {
                        editor?.canvas?.renderAll();
                      }, 500);
                    }
                  })
                  .catch((err) => {});
              })();
            }, 15000);
          }
          if (props.instances[props.currentInstance]?.data) {
            editor?.canvas?.loadFromJSON({
              objects: props?.instances?.[props.currentInstance].data,
            });
            editor?.canvas?.renderAll();
            setTimeout(() => {
              editor?.canvas?.renderAll();
            }, 500);
          }
        }, 1000);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    (async () => {
      props._updateCurrentInstance(0);
      getAllData();
    })();
    let interval = setInterval(() => {
      // Retrieve the current count value from the ref
      let count = countRef.current;
      console.log(count, props.instances.length, 'COUNT')
      setToggle((prev) => !prev); // toggle this to hit the load data json function

      if (count >= props.instances.length - 1) {
        // Reset count if it exceeds the array length
        count = 0;
      } else {
        // Increment count if it's within the array length
        count += 1;
      }

      // Update the ref with the new count value
      countRef.current = count;
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  // Helps to load the the data on every Interval
  useEffect(() => {
    if (countRef) {
      LoadDataFromJson(countRef.current);
    }
  }, [toggle]);

  useEffect(() => {
    let timeInterval = setInterval(() => {
      let mode = getTimeUpdates();
      if (mode !== timeMode) {
        setTimeMode(mode);
      }
    }, 60 * 1000);

    return () => {
      clearInterval(timeInterval);
      if (awsIntervalRef.current) {
        clearInterval(awsIntervalRef.current);
      }
      if (updateDataIntervalRef.current) {
        clearInterval(updateDataIntervalRef.current);
      }
    };
  }, []);
  function containsAbbreviatedMonth(text) {
    if(!text){
      return false;
    }
    const months = [
      'jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug',
      'sep', 'oct', 'nov', 'dec'
    ];
    const lowerText = text.toLowerCase();
    return months.some(month => lowerText.includes(month));
  }
  function containsAbbreviatedTime(text) {
    if(!text){
      return false;
    }
    const months = [
      'am', 'pm'
    ];
    const lowerText = text.toLowerCase();
    return months.some(month => lowerText.includes(month));
  }
  const LoadDataFromJson = (index) => {
    if (props.instances[index]?.data) {
      editor?.canvas?.clear();
      //   editor?.canvas?.set(props.instances[index]?.data);
      let d = props?.instances?.[index].data;
      console.log(d, "data...");
      let final_d = [];
      d.forEach((element) => {
        console.log("ELEMENT CALLING", element)
        if (element.type === "text") {
          if (isEmpty(element.text)) {
            return;
          }
          console.log(element.text, "element...IS HERE", aws_d);
          if (!aws_d) {
            return;
          }
          console.log("still going inside")
          let el_ = element;
          const regex = /< (.*?) >/;
          const match = el_?.id?.match(regex);
          const valueInsideBrackets = match ? match[1] : null;
          console.log(valueInsideBrackets);
          let el_split = valueInsideBrackets;
          console.log(el_split);
          let keyData = aws_d;
          function containsOnlyNumbers(str) {
            return /^\d+$/.test(str);
          }
          // console.log(containsOnlyNumbers(keyData))
          if (el_split?.length > 1) {
            keyData = aws_d[el_split];
            el_.text = keyData;
            console.log(el_, "EL DATA");
          }
          console.log(el_);
          final_d.push(el_);
        } 
        else if (element.type === "i-text") {
          console.log(element, 'THIS IS THE TEXT VALII CHEEZ', element.text, !element.text)
          
          if (isEmpty(element.text)) {
            return;
          }
          if (element?.text?.startsWith("Date") || containsAbbreviatedMonth(element?.text)) {
            let dateTimeString = currTime;
            let [dateString, timeString] = dateTimeString.split(', ');

            // Split the date string into day, month, and year
            let [day, month, year] = dateString.split('/');

            // Create a new date object
            let date = new Date(`${year}-${month}-${day}`);

            // Format the date
            let options = { day: '2-digit', month: 'short', year: 'numeric' };
            let formattedDate = date.toLocaleDateString('en-US', options);

            let el_ = element;
            el_.text = "Date: " + new Date().toLocaleString();
            el_.text = formattedDate;
            final_d.push(el_);
          } if (element?.text?.startsWith("Time") || containsAbbreviatedTime(element?.text)) {
            let dateTimeString = currTime;
            let [dateString, timeString] = dateTimeString.split(', ');

            // Split the date string into day, month, and year
            let [day, month, year] = dateString.split('/');

            // Create a new date object
            let date = new Date(`${year}-${month}-${day}`);

            // Format the date
            let options = { day: '2-digit', month: 'short', year: 'numeric' };
            let formattedDate = date.toLocaleDateString('en-US', options);
            console.log(timeString)
            let el_ = element;
            el_.text = timeString;
            final_d.push(el_);
          }else {
            final_d.push(element);
          }
          }else {
          //04/03/2024, 04:05 AM
          if (element?.text?.startsWith("Date") || containsAbbreviatedMonth(element?.text)) {
            let dateTimeString = currTime;
            let [dateString, timeString] = dateTimeString.split(', ');

            // Split the date string into day, month, and year
            let [day, month, year] = dateString.split('/');

            // Create a new date object
            let date = new Date(`${year}-${month}-${day}`);

            // Format the date
            let options = { day: '2-digit', month: 'short', year: 'numeric' };
            let formattedDate = date.toLocaleDateString('en-US', options);

            let el_ = element;
            el_.text = formattedDate;
            final_d.push(el_);
          } if (element?.text?.startsWith("Time") || containsAbbreviatedTime(element?.text)) {
            let dateTimeString = currTime;
            let [dateString, timeString] = dateTimeString.split(', ');

            // Split the date string into day, month, and year
            let [day, month, year] = dateString.split('/');

            // Create a new date object
            let date = new Date(`${year}-${month}-${day}`);

            // Format the date
            let options = { day: '2-digit', month: 'short', year: 'numeric' };
            let formattedDate = date.toLocaleDateString('en-US', options);
            console.log(timeString)
            let el_ = element;
            el_.text = timeString;
            final_d.push(el_);
          }else {
            final_d.push(element);
          }
        }
      });
      function removeEmptyTextElements(data) {
        return data.filter(item => {
            return !(item.type === 'text' || item.type === 'i-text') || item.text !== undefined && item.text.trim() !== '';
        });
    }
    
const filteredData = removeEmptyTextElements(final_d);
      console.log('loading...',final_d)
      editor?.canvas?.loadFromJSON({
        objects: filteredData,
      });
      editor?.canvas?.renderAll();
    }
  };

  useEffect(() => {
    // const platform = window.navigator.platform // different way of checking os 
    // console.log("🚀 ~ useEffect ~ platform:", platform)
    // const isRaspberryPiOS = () => {
    //   const platform = navigator.platform.toLowerCase();
    //   return platform.includes("linux") && platform.includes("arm");
    // };

    // if (isRaspberryPiOS()) {
    //   console.log("This appears to be Raspberry Pi OS.");
    // } else {
    //   console.log("This is not Raspberry Pi OS.");
    // }
    let timeout;
    // alert(osName)
    console.log(osName);
    (async()=>{await callApi({
      method: 'POST',
      endpoint: `api/screen/os-name/set/osName`,
      data: { 'osName': osName },
    })
      .then((res) => {})
      .catch((err)=>{

      })})();
    if (osName !== SUPPORTED_OPERATING_SYSTEM) {
      timeout = setTimeout(() => {
        navigate("/not-supported");
      }, 60 * 1000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);

  return (
    //<div className="w-full h-screen p-0 bg-gray-400">
    //<div className="h-[100%] w-[100%] bg-black shadow-xl">
    <div className="w-full h-screen p-0 bg-black cursor-none" style={{cursor: 'none'}}>
      <div className={`h-[100%] w-[100%] bg-transparent shadow-xl ${ timeMode === "MORNING"? `opacity-100` : `opacity-50`} `}>
        <div className={`relative shadow-lg w-full h-full`}>
          <FabricJSCanvas
            className={`w-full h-full`}
            onReady={onReady}
            selection={false}
          />
          <div className="absolute w-full h-full top-0 bg-transparent ">
            {/* / WRAPPER OVER A CANVAS TO DISABLE THE CANVAS/ */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default stateConnected(Preview);
