import React, { useEffect, useState } from "react";
import { callApi } from "../../common/utils/api";
import CustomInput from "../CustomInput";
import Error from "../../common/utils/Error";

const LocationsSidebarContent = (props) => {
  const { toggleSidebar, addRowData, currentValue, getData } = props;

  const [formData, setFormData] = useState({});

  useEffect(() => {
    setFormData({
      name: {
        title: "Location Name",
        value: currentValue?.row?.n ? currentValue?.row?.n : "",
        placeholder: "IMD, Pashan",
        type: "input",
      },
      address: {
        title: "Address",
        value: currentValue?.row?.ad ? currentValue?.row?.ad : "",
        placeholder: "address",
        type: "input",
      },
      city: {
        title: "City",
        value: currentValue?.row?.ci ? currentValue?.row?.ci : "",
        placeholder: "Pune",
        type: "input",
      },
      state: {
        title: "State",
        value: currentValue?.row?.st ? currentValue?.row?.st : "",
        placeholder: "Maharashtra",
        type: "input",
      },
      postal_code: {
        title: "Postal Code",
        value: currentValue?.row?.pc ? currentValue?.row?.pc : "",
        placeholder: "111045",
        type: "input",
      },
      managing_person: {
        title: "Managing Person",
        value: currentValue?.row?.mng ? currentValue?.row?.mng : "",
        placeholder: "",
        type: "input",
      },
      nick_name: {
        title: "Location Nick Name",
        value: currentValue?.row?.nn ? currentValue?.row?.nn : "",
        placeholder: "",
        type: "input",
        //   options: ["admin", "user"],
      },
    });
  }, [currentValue]);

  const handleChange = (fieldName, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: { ...prevFormData[fieldName], value },
    }));
  };

  const handleSubmit = async (e) => {
    let keys = Object.keys(formData);
    for (let i = 0; i < keys.length; i++) {
      if (formData[keys[i]].value === "") {
        Error(`${formData[keys[i]].title} Cannot be empty `);
        return;
      }
    }
    let dataObject = {};
    keys.forEach((key) => {
      dataObject = { [key]: formData[key]?.value, ...dataObject };
    });

    await callApi({
      method: "POST",
      endpoint: "api/locations",
      data: dataObject,
      alert: true,
    })
      .then((res) => {
        setFormData((prevFormData) => {
          const updatedFormData = {};
          Object.keys(prevFormData).forEach((key) => {
            updatedFormData[key] = {
              ...prevFormData[key],
              value: "",
            };
          });
          return updatedFormData;
        });
        toggleSidebar(); // this toggle will close the right
        addRowData(res); // add the data to the data grid row
      })
      .catch((err) => {});
  };
  const handleUpdate = async (e) => {
    let keys = Object.keys(formData);
    let dataObject = {};
    keys.forEach((key) => {
      if (formData[key]?.value) {
        dataObject = { [key]: formData[key]?.value, ...dataObject };
      }
    });
    await callApi({
      method: "PATCH",
      endpoint: `api/locations/${currentValue?.id}`,
      data: dataObject,
      alert: true,
    })
      .then((res) => {
        setFormData((prevFormData) => {
          const updatedFormData = {};
          Object.keys(prevFormData).forEach((key) => {
            updatedFormData[key] = {
              ...prevFormData[key],
              value: "",
            };
          });
          return updatedFormData;
        });
        toggleSidebar(); // this toggle will close the right
        // addRowData(res); // add the data to the data grid row
        getData();
      })
      .catch((err) => {});
  };

  return (
    <div className="flex flex-col w-[100%] h-[90%] gap-4 p-4">
      {Object.keys(formData)?.map((element) => {
        return formData[element].type === "input" ? (
          <div className="flex flex-col gap-2">
            <label>{formData[element]?.title}</label>
            <CustomInput
              placeholder={formData[element]?.placeholder}
              value={formData[element]?.value}
              onChange={(e) => handleChange(element, e.target.value)}
            />
          </div>
        ) : formData[element].type === "select" ? (
          <div className="flex flex-col gap-2">
            <label>{formData[element]?.title}</label>
            {formData[element]?.options ? (
              <select
                className="transition block w-full pl-4 h-[40px] rounded-[4px] border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 hover:ring-indigo-600 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                onChange={(e) => {
                  handleChange(element, e.target.value);
                  console.log(e.target.value);
                }}
              >
                {formData[element]?.options.length !== 0 ? (
                  formData[element]?.options?.map((option) => {
                    return <option value={option}>{option}</option>;
                  })
                ) : (
                  <option value={""}>no option</option>
                )}
              </select>
            ) : (
              <>
                Pass the key name options which is array of string in the object
                which mention types as select
              </>
            )}
          </div>
        ) : (
          <>TYPE NOT MATCHED</>
        );
      })}
      <button
        type="submit"
        onClick={currentValue ? handleUpdate : handleSubmit}
        className="transition mt-10 mb10 block w-full pl-4 h-[54px] rounded-[4px] font-semibold leading-6 text-white bg-indigo-500 border-0 py-1.5 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus: hover:bg-indigo-600  "
      >
        {currentValue ? "Update" : "Add"}
      </button>
    </div>
  );
};

export default LocationsSidebarContent;
