import {
  ADD_CIRCLE,
  ADD_IMAGE,
  ADD_INSTANCE,
  ADD_LINE,
  ADD_RECTANGLE,
  ADD_TEXT,
  DELETE_CIRCLE,
  DELETE_IMAGE,
  DELETE_INSTANCE,
  DELETE_LINE,
  DELETE_RECTANGLE,
  DELETE_TEXT,
  LOG_IN,
  LOG_OUT,
  UPDATE_ALL_INSTANCES,
  UPDATE_CIRCLE,
  UPDATE_CURRENT_INSTANCE,
  UPDATE_IMAGE,
  UPDATE_INSTANCE,
  UPDATE_LINE,
  UPDATE_RECTANGLE,
  UPDATE_TEXT,
} from "../../constant/strings";

const initialState = {
  auth: {
    token: null,
    role: null,
  },
  userData: null,
  configs: {
    sidebarOpen: true,
    notifications: [],
  },
  shapes: {
    rectangles: [],
    circles: [],
    lines: [],
    texts: [],
    images: [],
    // Add more shape categories as needed
  },
  instances: [{ id: 0, instance: 0, height: 500, width: 800, data: [] }],
  currentInstance: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOG_IN:
      return { ...state, auth: { ...state.auth, ...action.value } };
      
    case LOG_OUT:
      return { ...state, auth: { ...state.auth, ...action.value } };

    // Rectangle Actions
    case ADD_RECTANGLE:
      return {
        ...state,
        shapes: {
          ...state.shapes,
          rectangles: [...state.shapes.rectangles, action.value],
        },
      };

    case UPDATE_RECTANGLE:
      return {
        ...state,
        shapes: {
          ...state.shapes,
          rectangles: state.shapes.rectangles.map((rect) =>
            rect.id === action.value.id ? { ...rect, ...action.value } : rect
          ),
        },
      };

    case DELETE_RECTANGLE:
      return {
        ...state,
        shapes: {
          ...state.shapes,
          rectangles: state.shapes.rectangles.filter(
            (rect) => rect.id !== action.value.id
          ),
        },
      };

    // Circle Actions
    case ADD_CIRCLE:
      return {
        ...state,
        shapes: {
          ...state.shapes,
          circles: [...state.shapes.circles, action.value],
        },
      };

    case UPDATE_CIRCLE:
      return {
        ...state,
        shapes: {
          ...state.shapes,
          circles: state.shapes.circles.map((circle) =>
            circle.id === action.value.id
              ? { ...circle, ...action.value }
              : circle
          ),
        },
      };

    case DELETE_CIRCLE:
      return {
        ...state,
        shapes: {
          ...state.shapes,
          circles: state.shapes.circles.filter(
            (circle) => circle.id !== action.value
          ),
        },
      };

    // Line Actions
    case ADD_LINE:
      return {
        ...state,
        shapes: {
          ...state.shapes,
          lines: [...state.shapes.lines, action.value],
        },
      };

    case UPDATE_LINE:
      return {
        ...state,
        shapes: {
          ...state.shapes,
          lines: state.shapes.lines.map((line) =>
            line.id === action.value.id ? { ...line, ...action.value } : line
          ),
        },
      };

    case DELETE_LINE:
      return {
        ...state,
        shapes: {
          ...state.shapes,
          lines: state.shapes.lines.filter((line) => line.id !== action.value),
        },
      };

    case ADD_TEXT:
      return {
        ...state,
        shapes: {
          ...state.shapes,
          texts: [...state.shapes.texts, action.value],
        },
      };

    case UPDATE_TEXT:
      return {
        ...state,
        shapes: {
          ...state.shapes,
          texts: state.shapes.texts.map((text) =>
            text.id === action.value.id ? { ...text, ...action.value } : text
          ),
        },
      };

    case DELETE_TEXT:
      return {
        ...state,
        shapes: {
          ...state.shapes,
          texts: state.shapes.texts.filter((text) => text.id !== action.value),
        },
      };

    // Image Actions
    case ADD_IMAGE:
      return {
        ...state,
        shapes: {
          ...state.shapes,
          images: [...state.shapes.images, action.value],
        },
      };

    case UPDATE_IMAGE:
      return {
        ...state,
        shapes: {
          ...state.shapes,
          images: state.shapes.images.map((image) =>
            image.id === action.value.id ? { ...image, ...action.value } : image
          ),
        },
      };

    case DELETE_IMAGE:
      return {
        ...state,
        shapes: {
          ...state.shapes,
          images: state.shapes.images.filter(
            (image) => image.id !== action.value
          ),
        },
      };

    case ADD_INSTANCE:
      return {
        ...state,
        instances: [...state.instances, action.value],
      };

    case UPDATE_INSTANCE:
      return {
        ...state,
        instances: state.instances.map(
          (item, index) =>
            item.id === action.value.id ? { ...item, ...action.value } : item
          // index === action.value.id
          //   ? {
          //       id: index,
          //       instance: index,
          //       data: [...state.instances[index]?.data, ...action.value.data],
          //     }
          //   : item
        ),
      };

    case DELETE_INSTANCE:
      const indexToRemove = action.value;
      const updatedData = [
        ...state.instances.slice(0, indexToRemove),
        ...state.instances.slice(indexToRemove + 1),
      ].map((element, index) => {
        return { ...element, id: index, instance: index };
      });
      return {
        ...state,
        instances: updatedData,
      };
    case UPDATE_CURRENT_INSTANCE:
      return {
        ...state,
        currentInstance: action.value,
      };

    case UPDATE_ALL_INSTANCES:
      if(!action.value || action?.value?.length === 0){
        return {
          ...state,
          instances: [ {
            "id": 0,
            "instance": 0,
            "height": 500,
            "width": 800,
            "data": [],
          },
        ]
        }
      }
      return {
        ...state,
        instances: [...action.value],
      };

    default:
      return state;
  }
};

export default reducer;
