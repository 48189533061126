import React, { useState } from "react";
import { sidebarMenus } from "../../common/constant/strings";
import { useNavigate, useLocation } from "react-router-dom";
import TriangleRight from "../../common/core/icons/TriangleRight";
import TriangleLeft from "../../common/core/icons/TriangleLeft";
import Header from "../Header";

const Sidebar = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location?.pathname;
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  return (
    <div className="flex">
      <div
        className={`${
          isSidebarOpen ? "w-[16%] " : "w-[4.6%] "
        } z-[1] h-[100vh] pt-8 sticky top-0 duration-300 shadow-xl bg-bg_primary`}
      >
        <button
          className={`absolute flex justify-center align-center cursor-pointer bg-white shadow-xl -right-0 top-3 p-2 border-2 rounded-lg border border-primary text-color-primary ${!isSidebarOpen}`}
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        >
          {isSidebarOpen ? <TriangleLeft /> : <TriangleRight />}
        </button>
        <div className="flex gap-x-4 items-center border border-solid border-transparent border-opacity-50">
          <h1
            className={`origin-left font-light text-xl duration-200 text-primary-color m-2 font-roboto ${
              !isSidebarOpen && "scale-0"
            }`}
          >
            Mausam Darshan
          </h1>
        </div>
        <div
          // className="  your-specific-div overflow-y-scroll overflow-x-hidden h-[96%] p-2"
          className="flex flex-col align-center gap-4 mt-6"
        >
          {sidebarMenus?.map((Menu, index) => (
            <button
              key={index}
              className={`flex w-[100%] flex-row align-center cursor-pointer 
              border-transparent text-sm items-center p-3 gap-2
              ${isSidebarOpen ? "" : "justify-center"} ${
                pathName === Menu.src
                  ? "bg-indigo-200 "
                  : "hover:bg-white hover:text-gray-900"
              }`}
              onClick={() => navigate(Menu.src)}
            >
              <span>{Menu.icon}</span>
              <p
                className={`${
                  !isSidebarOpen && "hidden"
                } origin-left text-lg duration-200 ml-2  text-thin font-light`}
              >
                {Menu.title}
              </p>
            </button>
          ))}
        </div>
      </div>
      <div className={`${isSidebarOpen ? "w-[84%] " : "w-[96.6%] "} `}>
        <Header />
        {children}
      </div>
    </div>
  );
};

export default Sidebar;
