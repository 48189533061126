import {
  ADD_RECTANGLE,
  CIRCLE,
  LINE,
  RECTANGLE,
  LOG_IN,
  UPDATE_RECTANGLE,
  UPDATE_CIRCLE,
  DELETE_CIRCLE,
  ADD_LINE,
  UPDATE_LINE,
  DELETE_LINE,
  ADD_TEXT,
  UPDATE_TEXT,
  DELETE_TEXT,
  ADD_IMAGE,
  UPDATE_IMAGE,
  DELETE_IMAGE,
  ADD_CIRCLE,
  DELETE_RECTANGLE,
  ADD_INSTANCE,
  UPDATE_INSTANCE,
  DELETE_INSTANCE,
  UPDATE_CURRENT_INSTANCE,
  UPDATE_ALL_INSTANCES,
  LOG_OUT,
} from "../../constant/strings";

export const userLogin = (data) => {
  return {
    type: LOG_IN,
    value: data,
  };
};

export const userLogout = (data) => {
  return {
    type: LOG_OUT,
    value: data,
  };
};

// Rectangle Actions
export const addRectangle = (data) => ({
  type: ADD_RECTANGLE,
  value: data,
});

export const updateRectangle = (data) => ({
  type: UPDATE_RECTANGLE,
  value: data,
});

export const deleteRectangle = (id) => ({
  type: DELETE_RECTANGLE,
  value: id,
});

// Circle Actions
export const addCircle = (data) => ({
  type: ADD_CIRCLE,
  value: data,
});

export const updateCircle = (data) => ({
  type: UPDATE_CIRCLE,
  value: data,
});

export const deleteCircle = (id) => ({
  type: DELETE_CIRCLE,
  value: id,
});

// Line Actions
export const addLine = (data) => ({
  type: ADD_LINE,
  value: data,
});

export const updateLine = (data) => ({
  type: UPDATE_LINE,
  value: data,
});

export const deleteLine = (id) => ({
  type: DELETE_LINE,
  value: id,
});

// Text Actions
export const addText = (data) => ({
  type: ADD_TEXT,
  value: data,
});

export const updateText = (data) => ({
  type: UPDATE_TEXT,
  value: data,
});

export const deleteText = (id) => ({
  type: DELETE_TEXT,
  value: id,
});

// Image Actions
export const addImage = (data) => ({
  type: ADD_IMAGE,
  value: data,
});

export const updateImage = (data) => ({
  type: UPDATE_IMAGE,
  value: data,
});

export const deleteImage = (id) => ({
  type: DELETE_IMAGE,
  value: id,
});

export const addInstance = (data) => {
  return {
    type: ADD_INSTANCE,
    value: data,
  };
};

// Action creators for instances
export const updateInstance = (data) => {
  return {
    type: UPDATE_INSTANCE,
    value: data,
  };
};

export const deleteInstance = (id) => {
  return {
    type: DELETE_INSTANCE,
    value: id,
  };
};

export const updateCurrentInstance = (id) => {
  return {
    type: UPDATE_CURRENT_INSTANCE,
    value: id,
  };
};

export const updateAllInstances = (id) => {
  return {
    type: UPDATE_ALL_INSTANCES,
    value: id,
  };
};
