import React from "react";
import { stateConnected } from "../../common/store/redux_tools";
import TriangleLeft from "../../common/core/icons/TriangleLeft";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import { callApi } from "../../common/utils/api";

const CreatorHeader = (props) => {
  const {
    height,
    setHeight,
    width,
    setWidth,
    editor,
    name,
    canvasLoading,
    setCanvasLoading,
  } = props;
  const { id } = useParams();
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1); // Go back one step in the history
  };
  const handleSelectChange = (type, value) => {
    let canvasContainer =
      document.getElementsByClassName("canvas-container")[0];
    let lowerCanvas = document.getElementsByClassName("lower-canvas")[0];
    let upperCanvas = document.getElementsByClassName("upper-canvas")[0];

    if (type === "height") {
      setHeight(value);
      editor?.canvas.setHeight(value);
      canvasContainer.style.height = `${value}px`;
      lowerCanvas.style.height = `${value}px`;
      upperCanvas.style.height = `${value}px`;

      // props._updateInstance({
      //   id: props.currentInstance,
      //   height: +value,
      // });
      props.instances.forEach((el, index) => {
        props._updateInstance({
          id: index,
          height: +value,
        });
      });
    } else if (type === "width") {
      setWidth(value);
      editor?.canvas.setWidth(value);
      lowerCanvas.style.width = `${value}px`;
      upperCanvas.style.width = `${value}px`;

      // props._updateInstance({
      //   id: props.currentInstance,
      //   width: +value,
      // });
      props.instances.forEach((el, index) => {
        props._updateInstance({
          id: index,
          width: +value,
        });
      });
    }
    editor.canvas.renderAll();
    //
  };

  const handleSubmit = async () => {
    await callApi({
      method: "PATCH",
      endpoint: `api/screen/${id}`,
      data: { templ: props?.instances },
      alert: true,
    })
      .then((res) => {
        console.log("🚀 ~ file: CreatorHeader.jsx:56 ~ .then ~ res:", res);
      })
      .catch((err) => {
        console.log(JSON.stringify(err), 'ERRR', err)
      });
  };

  return (
    <div className="w-[100%] p-4 h-[80px] bg-gray-100 flex justify-between items-center border-b border-b-gray-300 shadow-xl ">
      <button
        onClick={goBack}
        className="p-4 border-0 border-primary flex items-center justify-center gap-2"
      >
        <TriangleLeft color={"#7367f0"} />
        <p className="text-primary">Back</p>
      </button>
      <div className="p-2 border-0 border-primary flex items-center justify-center gap-2 w-[160px]">
        <text className="truncate text-primary">{name}</text>
      </div>
      <div className="w-[50%] p-2 flex justify-center items-center gap-2 text-primary rounded-lg">
        <div className="w-[40%] flex items-center gap-2">
          <label className="text-xl">Height</label>
          {canvasLoading ? (
            <div className="transition block w-full flex items-center justify-center p-4 h-[54px] border border-primary text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 hover:ring-indigo-600 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 cursor-wait animate-pulse">
              <div class="h-4 w-full  bg-slate-200"></div>
            </div>
          ) : (
            <input
              type={"number"}
              value={height}
              min={300}
              max={1920}
              onChange={(event) =>
                handleSelectChange("height", event.target.value)
              }
              className="transition block w-full pl-4 h-[54px] border border-primary py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 hover:ring-indigo-600 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              defaultValue={height}
            />
          )}
        </div>
        <div className="w-[40%] flex items-center gap-2">
          <label className="text-xl">Width</label>
          {canvasLoading ? (
            <div className="transition block w-full flex items-center justify-center p-4 h-[54px] border border-primary text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 hover:ring-indigo-600 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 cursor-wait animate-pulse">
              <div class="h-4 w-full  bg-slate-200"></div>
            </div>
          ) : (
            <input
              type={"number"}
              value={width}
              min={300}
              max={1920}
              onChange={(event) =>
                handleSelectChange("width", event.target.value)
              }
              className="transition block w-full pl-4 h-[54px] border border-primary py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 hover:ring-indigo-600 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              defaultValue={width}
            />
          )}
        </div>
      </div>
      <div className="w-[30%] p-2 flex gap-4">
        {canvasLoading ? (
          <div
            className={
              "font-medium bg-white text-lg text-black cursor-pointer border w-[200px] border-primary flex items-center justify-center gap-4 cursor-wait animate-pulse"
            }
          >
            {/* <div class="h-2 w-full ml-4 bg-slate-200"></div> */}
            <p className="text-primary text-center">Preview</p>
            <svg
              height={24}
              width={24}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path
                fill="#7367f0"
                d="M13 18V20H17V22H7V20H11V18H3C2.44772 18 2 17.5523 2 17V4C2 3.44772 2.44772 3 3 3H21C21.5523 3 22 3.44772 22 4V17C22 17.5523 21.5523 18 21 18H13ZM4 5V16H20V5H4ZM10 7.5L15 10.5L10 13.5V7.5Z"
              ></path>
            </svg>
          </div>
        ) : (
          <Link
            // onClick={() => {}}
            to={`/preview/${id}`}
            className={
              "font-medium bg-white text-lg text-black cursor-pointer border w-[200px] border-primary flex items-center justify-center gap-4"
            }
          >
            <p className="text-primary text-center">Preview</p>
            <svg
              height={20}
              width={20}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path
                fill="#7367f0"
                d="M13 18V20H17V22H7V20H11V18H3C2.44772 18 2 17.5523 2 17V4C2 3.44772 2.44772 3 3 3H21C21.5523 3 22 3.44772 22 4V17C22 17.5523 21.5523 18 21 18H13ZM4 5V16H20V5H4ZM10 7.5L15 10.5L10 13.5V7.5Z"
              ></path>
            </svg>
          </Link>
        )}
        <button
          disabled={canvasLoading}
          onClick={() => {
            handleSubmit();
          }}
          className={`bg-primary font-medium text-lg text-black cursor-pointer pt-3 pb-3 p-2 w-[200px] border-primary ${
            canvasLoading ? "cursor-wait animate-pulse" : ""
          }`}
        >
          <p className="text-primary text-white">Publish</p>
        </button>
      </div>
    </div>
  );
};

export default stateConnected(CreatorHeader);
