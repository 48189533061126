import { connect } from "react-redux";

import {
  addCircle,
  addRectangle,
  userLogin,
  updateRectangle,
  deleteRectangle,
  updateCircle,
  deleteCircle,
  addLine,
  updateLine,
  deleteLine,
  addText,
  updateText,
  deleteText,
  addImage,
  updateImage,
  deleteImage,
  addInstance,
  updateInstance,
  deleteInstance,
  updateCurrentInstance,
  updateAllInstances,
  userLogout,
} from "../store/actions/index";
const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    _userLogin: (data) => {
      dispatch(userLogin(data));
    },
    _userLogout: (data) => {
      dispatch(userLogout(data));
    },
    _addRectangle: (data) => {
      dispatch(addRectangle(data));
    },
    _updateRectangle: (data) => {
      dispatch(updateRectangle(data));
    },
    _deleteRectangle: (id) => {
      dispatch(deleteRectangle(id));
    },
    _addCircle: (data) => {
      dispatch(addCircle(data));
    },
    _updateCircle: (data) => {
      dispatch(updateCircle(data));
    },
    _deleteCircle: (id) => {
      dispatch(deleteCircle(id));
    },
    _addLine: (data) => {
      dispatch(addLine(data));
    },
    _updateLine: (data) => {
      dispatch(updateLine(data));
    },
    _deleteLine: (id) => {
      dispatch(deleteLine(id));
    },
    _addText: (data) => {
      dispatch(addText(data));
    },
    _updateText: (data) => {
      dispatch(updateText(data));
    },
    _deleteText: (id) => {
      dispatch(deleteText(id));
    },
    _addImage: (data) => {
      dispatch(addImage(data));
    },
    _updateImage: (data) => {
      dispatch(updateImage(data));
    },
    _deleteImage: (id) => {
      dispatch(deleteImage(id));
    },
    _addInstance: (data) => {
      dispatch(addInstance(data));
    },
    _updateInstance: (data) => {
      dispatch(updateInstance(data));
    },
    _deleteInstance: (id) => {
      dispatch(deleteInstance(id));
    },
    _updateCurrentInstance: (id) => {
      dispatch(updateCurrentInstance(id));
    },
    _updateAllInstances: (id) => {
      dispatch(updateAllInstances(id));
    },
  };
};

export const stateConnected = (Component) => {
  let ExportComponent = (props) => {
    return <Component {...props}></Component>;
  };

  return connect(mapStateToProps, mapDispatchToProps)(ExportComponent);
};
