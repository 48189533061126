import React from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import Sidebar from "./components/sidebar/Sidebar";
import HomePage from "./pages/HomePage";
import Aws from "./pages/Aws";
import Display from "./pages/Display";
import CommandCenter from "./pages/CommandCenter";
import Login from "./pages/Login";
import Location from "./pages/Location";
import Users from "./pages/Users";
import { stateConnected } from "./common/store/redux_tools";
import Creator from "./pages/Creator";
import Preview from "./pages/Preview";
import NotSupportedPage from "./pages/NotSupportedPage";
const Routing = (props) => {
  const location = useLocation();

  return (
    <div>
      {location.pathname === "/login" ? (
        <Routes>
          <Route
            path="/login"
            element={
              props.auth?.token?.length > 0 ? <Navigate to="/" /> : <Login />
            }
          />
        </Routes>
      ) : props.auth?.token?.length > 0 ? (
        <Routes>
          {/* <Route
            path="/"
            element={
              <Sidebar>
                <HomePage />
              </Sidebar>
            }
          /> */}
          <Route path="/" element={<Navigate to="/location" replace />} />
          <Route
            path="/location"
            element={
              <Sidebar>
                <Location />
              </Sidebar>
            }
          />
          <Route
            path="/aws"
            element={
              <Sidebar>
                <Aws />
              </Sidebar>
            }
          />
          <Route
            path="/display"
            element={
              <Sidebar>
                <Display />
              </Sidebar>
            }
          />
          <Route
            path="/users"
            element={
              <Sidebar>
                <Users />
              </Sidebar>
            }
          />
          {/* <Route
            path="/command-center"
            element={
              <Sidebar>
                <CommandCenter />
              </Sidebar>
            }
          /> */}
          {/* <Route path="/command-center" element={<CommandCenter />} /> */}
          <Route path="/creator/:id" element={<Creator />} />
          <Route path="/preview/:id" element={<Preview />} />
          <Route path="/not-supported" element={<NotSupportedPage />} />
        </Routes>
      ) : (
        <Navigate to="/login" />
      )}
    </div>
  );
};

export default stateConnected(Routing);
