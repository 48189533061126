import React from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarQuickFilter,
  useGridApiRef,
} from "@mui/x-data-grid";
import { stateConnected } from "../common/store/redux_tools";
import AddIcon from "../common/core/icons/AddIcon";

const ReusablePageComponent = (props) => {
  const childRef = useGridApiRef();

  const {
    heading,
    subHeading,
    column,
    rows,
    toggleSidebar,
    loading,
    setLoading,
    onRefChange,
  } = props;

  React.useEffect(() => {
    // Call the callback function provided by the parent
    // to pass the ref up to the parent
    if (onRefChange) {
      onRefChange(childRef);
    }
  }, []);

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <div className="flex gap-2 pl-8">
          <GridToolbarColumnsButton
            sx={{
              borderRadius: "6px",
              // paddingY: "0px",
              paddingX: "14px",
              paddingY: "4px",
              fontFamily: "Poppins",
              color: "#2a6cdf",
              border: "1px solid",
              borderColor: "rgb(44,108,244,0.2)",
              button: {
                bgcolor: "purple",
                padding: "10px",
              },
            }}
          />
          <GridToolbarFilterButton
            sx={{
              borderRadius: "6px",
              // paddingY: "0px",
              paddingX: "14px",
              paddingY: "4px",
              fontFamily: "Poppins",
              color: "#2a6cdf",
              border: "1px solid",
              borderColor: "rgb(44,108,244,0.2)",
              button: {
                bgcolor: "purple",
                padding: "10px",
              },
            }}
          />
          {/* <GridToolbarDensitySelector /> */}
          <GridToolbarExport
            sx={{
              borderRadius: "6px",
              // paddingY: "0px",
              paddingX: "14px",
              paddingY: "4px",
              fontFamily: "Poppins",
              color: "#2a6cdf",
              border: "1px solid",
              borderColor: "rgb(44,108,244,0.2)",
              button: {
                bgcolor: "purple",
                padding: "10px",
              },
            }}
          />
        </div>
        <div className="flex items-center">
          <GridToolbarQuickFilter
            sx={{
              marginRight: "6px",
              border: "1px solid",
              borderColor: "white",
              backgroundColor: "#d3d3d350",
              borderRadius: "6px",
              paddingY: "0px",
              paddingX: "14px",
              input: {
                bgcolor: "transparent",
                padding: "10px",
                
              },
              svg: {
                fill: "lightgray",
              },
            }}
          />
          <button
            className="p-3 px-4 text-blue-500 flex  font-medium bg-blue-100 rounded-[6px]"
            onClick={toggleSidebar}
          >
            <p>Add</p>
            <AddIcon color={"rgb(0,0,255,0.6)"} />
          </button>
        </div>
      </GridToolbarContainer>
    );
  };

  return (
    <div className="">
      <div className="m-2 text-bold">
        <h2 className="text-[32px] ml-4 mt-4">{heading}</h2>
        <p className="ml-4 mt-1 text-thin text-8">{subHeading}</p>
      </div>
      <DataGrid
        apiRef={childRef}
        rows={rows}
        columns={column}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25,
            },
          },
        }}
        loading={loading}
        slots={{
          toolbar: CustomToolbar,
          //  noRowsOverlay: <>No Data Available</>
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            // printOptions: { disableToolbarButton: true },
            // csvOptions: { disableToolbarButton: true },
          },
        }}
        pageSizeOptions={[25, 50, 100]}
        // disableColumnFilter
        // disableColumnSelector
        // disableDensitySelector
        disableRowSelectionOnClick
        density="comfortable"
        checkboxSelection={false}
        sx={{
          // padding: 2,
          // margin: 2,
          height: "calc(100vh - 160px)",
          boxShadow: 0,
          border: 0,
          borderColor: "primary.light",
          "& .MuiDataGrid-cell:hover": {},
          "& .MuiDataGrid-row:hover": {
            backgroundColor: "primary.main",
            // color: "white",
            // fill: "white",
            // accentColor: "white",
          },
          ".MuiDataGrid-columnSeparator": {
            display: "none",
          },
          "& .MuiDataGrid-columnHeader": {
            backgroundColor: "rgba(224, 231, 255, 0.25)",
            fontWeight: "bold",
          },
          "& .MuiDataGrid-row": {
            height: "60px",
          },
        }}
      />
    </div>
  );
};

export default stateConnected(ReusablePageComponent);
