import React, { useEffect, useState } from "react";
import ReusablePageComponent from "../components/ReusablePageComponent";
import { callApi } from "../common/utils/api";
import { stateConnected } from "../common/store/redux_tools";
import moment from "moment-timezone";
import Avatar from "react-avatar";
import RightSidebar from "../components/sidebar/RightSidebar";
import UsersSidebarContent from "../components/users/UsersSidebarContent";

const Users = (props) => {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);

  const [parentRef, setParentRef] = useState({}); // ref for the data grid Mui

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const onSetSidebarOpen = (open) => {
    setSidebarOpen(open);
  };
  const toggleChange = () => {
    setSidebarOpen((prev) => !prev);
  };

  // Callback function to receive the child ref
  const handleChildRefChange = (childRef) => {
    // storing it in the parentRef or perform any other actions
    // parentRef = childRef;
    setParentRef(childRef);
  };

  const addRowData = (singleRow) => {
    setRows((prevRows) => [...prevRows, { ...singleRow, id: singleRow?._id }]);
  };

  useEffect(() => {
    (async () => {
      await callApi({
        endpoint: "api/users",
      })
        .then((res) => {
          let col = res?.columns;
          let rows = res?.data;
          let columnData = Object.keys(col)?.map((key) => {
            return {
              field: key,
              headerName: col[key],
              //   sortable: false,
              //   filterble: false,
              flex: 1,
              renderCell:
                key === "usn"
                  ? (params) => (
                      <div className="flex items-center">
                        <Avatar
                          name={params.row?.[key]}
                          size="35"
                          round={true}
                        />{" "}
                        &nbsp;&nbsp;{" "}
                        <span className="font-medium">{params.row?.[key]}</span>
                      </div>
                    )
                  : key === "role" && res?.roleMap
                  ? (params) => (
                      <div className="flex items-center">
                        {res?.roleMap[params.row?.[key]] === "admin" ? (
                          <>
                            <div className="bg-green-100 text-green-800 px-2 py-1 rounded-full">
                              Administrator
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="bg-sky-100 text-sky-800 px-2 py-1 rounded-full">
                              Incharge
                            </div>
                          </>
                        )}
                      </div>
                    )
                  : key === "ct" || key === "ut"
                  ? (params) => {
                      const timestamp = params.row?.[key];
                      const date = new Date(timestamp);

                      const formattedDate = moment(date)
                        .tz("Asia/Kolkata")
                        .format("h:mm:ss A z MMM D, YYYY");

                      return formattedDate;
                    }
                  : (params) => params.row?.[col],
            };
          });
          setColumns(columnData);
          const rowsData = rows?.map((user) => {
            let singleObject = {};
            Object.keys(col).forEach((singleRow) => {
              singleObject = { ...singleObject, [singleRow]: user[singleRow] };
            });
            return {
              id: user._id,
              ...singleObject,
            };
          });
          setRows(rowsData);
          setLoading(false);
        })
        .catch((err) => {});
    })();
  }, []);

  return (
    <div>
      <RightSidebar
        sidebarOpen={sidebarOpen}
        onSetSidebarOpen={onSetSidebarOpen}
        sidebarContent={
          <UsersSidebarContent
            toggleSidebar={toggleChange}
            addRowData={addRowData}
          />
        }
      />

      <ReusablePageComponent
        heading={"Users"}
        subHeading={"Users the Locations"}
        column={columns}
        rows={rows}
        loading={loading}
        setLoading={setLoading}
        toggleSidebar={toggleChange}
        onRefChange={handleChildRefChange} //lift refrence from child to parent
      />
    </div>
  );
};

export default stateConnected(Users);
